import tw from "twin.macro";
import styled from "styled-components";

export interface StyledMedalCardProps {
  backgroundColor: string;
  size?: string;
  location?: string;
  status?: string;
  startedPath?: boolean;
}
export const StyledMedalCard = styled.div.attrs((props) => ({
  className: props.className,
}))<StyledMedalCardProps>`
  ${({ backgroundColor }) =>
    backgroundColor === "Blue"
      ? `background: linear-gradient(341.28deg, #001273 12.6%, #00ABE4 77.64%);`
      : backgroundColor === "Green"
      ? `background: linear-gradient(156.71deg, #45AA4F 28.16%, #0C3F11 100%);`
      : backgroundColor === "Gold" &&
        `color: rgb(0 0 0) !important; background: linear-gradient(125.21deg, #FFB500 3.61%, #FFFFFF 16.89%, #FFB500 31.54%, #FFB500 72.41%, #7C5903 100.92%); `}

  ${({ status, startedPath }) =>
    status === null && startedPath === true && "opacity: 0.5;"}
    
${({ size, status }) =>
    size === "default"
      ? "width:352px; height:544px;"
      : size === "big"
      ? "width:384px; height:max-content; padding-bottom: 32px;"
      : size === "small" && status === null
      ? "width:352px; height: 100%; padding-bottom: 24px;"
      : size === "small" &&
        status !== null &&
        "width:352px; height: 100%; padding-bottom: 24px;"}

${({ location, backgroundColor }) =>
    location === "landing"
      ? "h2 {padding-bottom: 48px; padding-top: 24px;}"
      : location === "learning-path"
      ? "h2 {padding-bottom: 16px; padding-top: 18px;}"
      : location === "detail" && backgroundColor === "Blue"
      ? "h2{ padding-bottom: 24px; }"
      : location === "detail" && backgroundColor === "Green"
      ? "h2{ padding-bottom: 16px; }"
      : location === "detail" &&
        backgroundColor === "Gold" &&
        "h2{ padding-bottom: 8px; }"}

${tw`text-white mx-4 rounded-3xl`}
h2 {
    ${tw`font-primaryBold text-2xl `}
  }
  p > {
    ${tw`text-base font-primaryRegular`}
  }
  img > {
    ${tw`w-[92px] h-[120px] inline-block`}
  }
  .Sparkle {
    ${tw`absolute left-full top-0`}
    transform: translate(calc(-50% - 5px), -50%);
  }
`;

export const StyledDescriptionGreen = styled.div.attrs((props) => ({
  className: props.className,
}))`
  p {
    padding-bottom: 0px !important text-center;
    ${tw`font-primarySemibold lg:(py-0 text-lg)`}
  }
`;

export const StyledDescriptionGold = styled.div.attrs((props) => ({
  className: props.className,
}))`
  p {
    padding-bottom: 0px !important;
    ${tw`lg:(py-0)`}
  }
`;

export const StyledStepContainer = styled.div.attrs((props) => ({
  className: props.className,
}))`
  ${tw`grid gap-2 items-center`}
  grid-template-columns: 24px auto;
  .stepInfo {
    grid-column: 2 / 3;
  }
`;
