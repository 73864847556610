import { useState } from "react";
import { AxiosError } from "axios";
import { HomeModel } from "../../../data/models";
import { useCustomEffect } from "../../hooks/useHookEffect";
import {
  useCaseHome,
  useCaseLearningPath,
  useCaseProductions,
  useCaseSubscriptions,
} from "../../../domain/useCases";
import {
  homeDataService,
  productionsDataService,
  subscriptionsDataService,
} from "../../../data/services";
import companySubscription from "../../assets/images/companySubscription.svg";
import { CategoryEntity, LearningPathEntity, SubscriptionEntity } from "../../../domain/entities";
import { useNavigation } from "../../hooks";
import {
  getSavedLearningPath,
  getSessionToken,
  getSubscriptionStatus,
} from "../../../data/dto/selectors";
import subscriptionsUfo from "../../assets/images/subscriptionsUfo.svg";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { learningPathDataService } from "../../../data/services/learningPath.data.service";

export function useHomeViewModel() {
  const { navigateTo, getCurrentPathName } = useNavigation();
  const token = useSelector(getSessionToken);
  const learningPathUuid = useSelector(getSavedLearningPath)

  const userSubscription = useSelector(getSubscriptionStatus);

  const [homeData, setHomeData] = useState<HomeModel>({} as HomeModel);

  const [favoritesProductions, setFavoritesProductions] = useState<
    Array<CategoryEntity> | []
  >([]);
  const [productions, setProductions] = useState<Array<CategoryEntity> | []>(
    []
  );
  const [podcastProductions, setPodcastProductions] =
    useState<CategoryEntity>();
  const [listLearningPathsToRent, setlistLearningPathsToRent] = useState<
    Array<LearningPathEntity>
  >([]);
  const [gameProductions, setGameProductions] = useState<CategoryEntity>();
  const [subscriptionsList, setSubscriptionsList] = useState<
    Array<SubscriptionEntity> | []
  >([]);

  const { get } = useCaseSubscriptions(subscriptionsDataService());
  const { getFiltered, getAll, getGameById } = useCaseProductions(
    productionsDataService()
  );

  const { getlistLearningPathsToRentToRent } = useCaseLearningPath(
    learningPathDataService()
  );

  const { getHomeData, getFavorites } = useCaseHome(homeDataService());
  const productionFilter = {
    limit: 2,
  };
  function successProductionFiltered(productions: Array<CategoryEntity>) {
    const videoProductionsList = productions.filter(
      (currentProduction) =>
        currentProduction.slug !== "podcast" &&
        currentProduction.slug !== "juegos"
    );
    setProductions(videoProductionsList);
  }
  function errorProductionFiltered(e: AxiosError) {
    console.log("errorProductionFiltered", e);
  }

  function successGamePodcastProductions(productions: Array<CategoryEntity>) {
    const podcastProductionsList = productions.find(
      (currentProduction) => currentProduction.slug === "podcast"
    );
    const gameProductionsList = productions.find(
      (currentProduction) => currentProduction.slug === "juegos"
    );
    setPodcastProductions(podcastProductionsList);
    setGameProductions(gameProductionsList);
  }
  function errorGamePodcastProductions(e: AxiosError) {
    console.log("errorGamePodcastProductions", e);
  }

  function successGetListOfLearningPathsToRent(
    learningPaths: Array<LearningPathEntity>
  ) {
    setlistLearningPathsToRent(learningPaths);
  }

  function errorGetListOfLearningPathsToRent(e: AxiosError) {
    console.log("errorGetListOfLearningPathsToRent", e);
  }

  /*Game card */
  const handleSuccessGameById = (data: any) => {
    if (data.url) {
      window.open(`${data.url}`, "_blank");
    }
  };

  const handleErrorGameById = (error: any) => {
    if (error.response.data.message) {
      if (!token) {
        toast.error("Debes iniciar sesión para acceder al contenido");
      } else {
        userSubscription?.stripeStatus !== "active"
          ? toast.error("Debes subscribirte para acceder al contenido")
          : toast.error(error.response.data.message);
      }
    }
  };

  const onGetGameById = (gameId: string) => {
    getGameById({
      id: gameId,
      success: handleSuccessGameById,
      error: handleErrorGameById,
    });
  };

  /*Handle Home data */
  const onGetHomeDataSuccess = (homeData: HomeModel) => {
    setHomeData(homeData);
  };

  /*Handle Home favorites */
  const onGetHomeFavoritesSuccess = (
    favoritesProductions: Array<CategoryEntity>
  ) => {
    setFavoritesProductions(favoritesProductions);
  };

  /*Subscriptions */
  /* handle subscriptions */
  const onSuccessSubscriptionList = (data: Array<SubscriptionEntity>) => {
    setSubscriptionsList([
      {
        name: "Alquiler",
        description:
          "Podrás alquilar cualquiera de los títulos que componen nuestro catálogo",
        items: [
          "Podrás alquilar cualquiera de los títulos que componen nuestro catálogo",
          "El alquiler estará habilitado durante un periodo de 48 horas.",
        ],
        images: [subscriptionsUfo],
        tag: "",
        tyc: "terminos-y-condiciones",
        minimumTermLabel: "Valor variable según contenido",
        callToAction: () => {
          navigateTo("/rentals");
        },
        callToActionLabel: "Ver precios",
      },
      ...data,
      {
        name: "Empresas",
        items: [
          "Si necesita una propuesta personalizada o información sobre nuestros planes, por favor contáctanos: empresas@storytraining.com",
        ],
        images: [companySubscription],
        tag: "",
        tyc: "content/terminos-y-condiciones",
        minimumTermLabel: "",
        callToActionLabel: "Contáctanos",
      },
    ]);
  };

  const handleCallToActionCard = () => {
    navigateTo(`/subscriptions`);
  };

  useCustomEffect(() => {
    getFiltered({
      filter: productionFilter,
      success: successProductionFiltered,
      error: errorProductionFiltered,
    });
    getHomeData({
      success: onGetHomeDataSuccess,
    });
    getAll({
      success: successGamePodcastProductions,
      error: errorGamePodcastProductions,
    });
    getFavorites({
      success: onGetHomeFavoritesSuccess,
    });
    get({
      success: onSuccessSubscriptionList,
    });

    getlistLearningPathsToRentToRent({
      success: successGetListOfLearningPathsToRent,
      error: errorGetListOfLearningPathsToRent,
    });
  }, []);

  const quotes = Array(5).fill({
    quote:
      '"La educación debe verse diferente, el mundo ha cambiado.StoryTraining es una apuesta dinámica y didáctica de aprendizaje."',
    quoteBy: "Mateo",
    position: "Diseñador UX",
    course: "Curso en Management",
  });

  return {
    token,
    userSubscription,
    homeData,
    favoritesProductions,

    productions,
    podcastProductions,
    gameProductions,

    subscriptionsList,
    handleCallToActionCard,
    navigateTo,
    quotes,
    listLearningPathsToRent,
    onGetGameById,
  };
}
