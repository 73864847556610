import { AuthenticationService, emailAndPasswordInput } from "../../services/authentication.service";


export function useCaseSignIn(service: AuthenticationService) {
    return {
        run({ email, password, success, error }: emailAndPasswordInput) {
            return service.emailAndPasswordLogin({ email, password, success, error })
        }
    }
}

