import { AxiosError } from "axios";
import { useLayoutEffect, useState } from "react";
import { LearningPathModel } from "../../../data/models/learningPath.model";
import { learningPathDataService } from "../../../data/services/learningPath.data.service";
import { LearningPathEntity, MedalEntity } from "../../../domain/entities";
import { useCaseLearningPath } from "../../../domain/useCases";
import { useCustomEffect, useNavigation } from "../../hooks";

export function useLearningPathsViewModel() {
  const { getCurrentPathName, navigateTo } = useNavigation();

  const [sizeScreen, setSizeScreen] = useState({ x: 0, y: 0 });
  const [listOfMedals, setListOfMedals] = useState<Array<MedalEntity>>([]);
  const [listLearningPathsToRent, setlistLearningPathsToRent] = useState<
    Array<LearningPathEntity>
  >([]);

  const { getListMedals, getlistLearningPathsToRentToRent } = useCaseLearningPath(
    learningPathDataService()
  );
  function successGetListOfMedals(medals: Array<MedalEntity>) {
    setListOfMedals(medals);
  }

  function errorGetListOfMedals(e: AxiosError) {
    console.log("errorGetListOfMedals", e);
  }

  function successGetListOfLearningPathsToRent(
    learningPaths: Array<LearningPathEntity>
  ) {
    setlistLearningPathsToRent(learningPaths);
  }

  function errorGetListOfLearningPathsToRent(e: AxiosError) {
    console.log("errorGetListOfLearningPathsToRent", e);
  }

  const handleStartLearningPath = (uuid: string) => {
    navigateTo(`/learning-path-detail?uuid=${uuid}`)
  };

  useCustomEffect(() => {
    getListMedals({
      success: successGetListOfMedals,
      error: errorGetListOfMedals,
    }),
      getlistLearningPathsToRentToRent({
        success: successGetListOfLearningPathsToRent,
        error: errorGetListOfLearningPathsToRent,
      });
  }, []);

  useLayoutEffect(() => {
    const handleResize = () => {
      setSizeScreen({ x: window.innerWidth, y: window.innerHeight });
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return {
    sizeScreen,
    listOfMedals,
    listLearningPathsToRent,
    getCurrentPathName,
    navigateTo,
    handleStartLearningPath,
  };
}
