import { LearningPathService } from "../../domain/services/learningPath.service";
import { handleResponse } from "../../domain/shared";
import { httpService } from "../http/services/http";
import { LearningPathModel } from "../models/learningPath.model";
import { MedalModel } from "../models/medal.model";
import { Endpoints } from "./endpoints";
import { useSelector } from "react-redux";
import { getSessionToken } from "../dto/selectors";
import { isConstructorDeclaration } from "typescript";
const baseUrl = process.env.REACT_APP_API_BASE
export interface ListMedalsResponse {
    data: {
        data: {
            items: MedalModel;
        };
    }
}

export interface listLearningPathsToRentResponse {
    data: {
        data: {
            items: Array<LearningPathModel>;
        }
    }
}

export function learningPathDataService(): LearningPathService {
    const token = useSelector(getSessionToken);
    const { get } = httpService();
    return {
        async getListMedals({ success, error }: handleResponse) {

            // fetch(`${baseUrl}${Endpoints.listMedals}`, {
            //     headers: {
            //         Authorization: `Bearer ${token}`
            //     }
            // })
            //     .then(response => {
            //         if (!response.ok) {
            //             throw new Error('Network response was not ok');
            //         }
            //         return response.json();
            //     })
            //     .then((data) => {
            //         if (
            //             success
            //         ) {
            //             // success(data.data.values)
            //         }
            //     })
            //     .catch(e => {
            //         if (error) {
            //             console.log(e)
            //             error(e);
            //         }
            //     });
        },

        async getlistLearningPathsToRentToRent({ success, error }: handleResponse) {
            // Axios was unable to format the data response from the backend
            fetch(`${baseUrl}${Endpoints.rentLearningPaths}`, {
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((data) => {
                    if (
                        success
                    ) {
                        success(data.data)
                    }
                })
                .catch(e => {
                    if (error) {
                        console.log(e)
                        error(e);
                    }
                });
        },
        async getlistOfRentedLearningPaths({ success, error }: handleResponse) {
            // Axios was unable to format the data response from the backend
            fetch(`${baseUrl}${Endpoints.listLearningPathsToRent}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((data) => {
                    if (
                        success
                    ) {
                        success(data.data)
                    }
                })
                .catch(e => {
                    if (error) {
                        console.log(e)
                        error(e);
                    }
                });
        }
    }
}