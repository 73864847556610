import { FC } from "react";
import { DefaultLayout } from "../../layouts";
import { useCatalogViewModel, usePreviewViewModel } from "../../viewModels";
import { Button, CategoryCarousel, Modal, Navbar } from "../../components";
import {
  StyledHomeContainer,
  StyledModal,
  StyledSection,
} from "./catalog.styles";
import { Breadcrumb } from "../../components";
import subscriptionsUfo from "../../assets/images/subscriptionsUfo.svg";
import {
  InformationSection,
  RelatedAreas,
  SeasonsSection,
  UsersCommentsSection,
  VideoPreview,
} from "../preview/components";
import { StyledBlurWall, StyledButton } from "../preview/preview.styles";
import {
  StyledProfileSubscriptionSection,
  StyledSubscriptionImage,
} from "../profile/profile.styles";
import { GoSubscribe } from "../home/components";
import { GameSection, Podcast } from "./components";

export const Catalog: FC = () => {
  const {
    breadcrumbItemsElements,
    productions,
    podcastProductions,
    gameProductions,
    onGetGameById,
  } = useCatalogViewModel();
  const {
    showModalPreview,
    handleModalClose,
    isProductionRented,
    token,
    userSubscription,
    videoOptions,
    similarProductions,
    currentSeasonNumber,
    currentProduction,
    currentChapter,
    nextChapter,
    onGetChapter,
    navigateTo,
    handleSaveProduction,
    isProductionSaved,
    handlePlayerReady,
    videoTimeLeft,
    handleCallToActionRental,
    isPodcast,
    showModalRating,
    onSubmitRating,
    onCancelRating,
    onClickRatingButton,
    userCanComment,
    comments,
    handleChangeCommentsPage,
  } = usePreviewViewModel();
  return (
    <DefaultLayout>
      <div className="w-full lg:hidden">
        <Navbar />
      </div>
      <div className="px-8 pb-8 lg:px-14 hidden lg:block">
        <Breadcrumb breadcrumbItems={breadcrumbItemsElements} />
      </div>
      <StyledHomeContainer>
        {!token && (
          <StyledProfileSubscriptionSection>
            <div></div>
            <div className="relative  w-full max-w-theme flex justify-center mx-auto">
              <StyledSubscriptionImage src={subscriptionsUfo} />
              <Button
                className="mx-auto top-14 lg:top-0 z-10 absolute lg:relative"
                onClick={() => navigateTo("/signup")}
              >
                ¡Regístrate ahora!
              </Button>
            </div>
          </StyledProfileSubscriptionSection>
        )}

        <h1 className="block py-4  text-center text-xl leading-9 font-primaryBold text-white sm:(text-[2rem] leading-[2.375rem]) lg:hidden">
          CATÁLOGO
        </h1>

        <StyledSection>
          <article className="flex flex-col items-center gap-y-3">
            <h2>
              <span>NUESTRAS PRODUCCIONES</span>
            </h2>
            <p className="hidden lg:block">
              Todas nuestras producciones son originales y han sido realizadas
              por el equipo de "storytraining" utilizando su gran experiencia
              práctica y pedagógica en cada una de nuestras Áreas Temáticas. Con
              todas ellas aprenderás mientras te diviertes. ¡No te lo pierdas!
            </p>
          </article>
        </StyledSection>

        {productions.map((production, index) => {
          const {
            title,
            productions: productionList,
            description,
          } = production;
          return (
            <StyledSection
              className="StyledSection"
              key={`S-${index}`}
              withoutPadding
            >
              <CategoryCarousel
                isCatalog
                key={`P-${index}`}
                categoryName={title}
                categoryDescription={description}
                productions={productionList}
                hideCategory={true}
                slidesToShow={4}
                slidesToShowMobile={1}
                currentCardPaddingMobile="72px"
                fullWidth
              />
            </StyledSection>
          );
        })}
        {podcastProductions?.productions.length && (
          <StyledSection withoutPadding={true} className="StyledSection">
            <Podcast podcastProductions={podcastProductions} />
          </StyledSection>
        )}
        {gameProductions?.games.length && (
          <StyledSection withoutPadding={true} className="StyledSection">
            <GameSection
              gameProductions={gameProductions}
              onClickCard={onGetGameById}
            />
          </StyledSection>
        )}
        {!token ? (
          <div>
            <h2 className="text-center text-2xl leading-9 font-primaryBold text-white">
              REGÍSTRATE Y FORMA PARTE DE NUESTRA HISTORIA
            </h2>
            <p className="font-primarySemibold text-sm text-center text-white px-4 pt-4 lg:hidden">
              El mejor contenido para el desarrollo de tus soft skills a través
              de Series Tv, Películas, Documentales...¡Cuándo quieras y dónde
              quieras!
            </p>
            <GoSubscribe />
          </div>
        ) : (
          <StyledProfileSubscriptionSection>
            <div></div>
            <div className="relative  w-full max-w-theme flex justify-center mx-auto">
              <StyledSubscriptionImage
                src={subscriptionsUfo}
                alt={"Imagen tipo de suscripción"}
                title={"Imagen tipo de suscripción"}
              />
              <Button
                className="mx-auto top-14 lg:top-0 z-10 absolute lg:relative"
                onClick={() => navigateTo("/subscriptions")}
              >
                Administrar mi suscripción
              </Button>
            </div>
          </StyledProfileSubscriptionSection>
        )}
        {productions.length % 2 === 0 && (
          <StyledSection className="StyledSection" />
        )}
      </StyledHomeContainer>

      <StyledModal
        showModal={showModalPreview}
        backgroundColor="black"
        title={currentProduction.title}
        onClose={handleModalClose}
      >
        <div className="w-preview py-6">
          <VideoPreview
            videoOptions={videoOptions}
            imgPreview={currentProduction.imgPreview}
            format={currentProduction.format}
            terms={currentProduction.terms}
            duration={currentProduction.duration}
            handlePlayerReady={handlePlayerReady}
            timeLeft={videoTimeLeft}
            chapterName={
              currentChapter?.name
                ? currentChapter?.name
                : currentProduction.title
            }
            productionName={currentProduction.title}
            currentSeasonNumber={currentSeasonNumber}
            nextChapterName={nextChapter?.name ? nextChapter?.name : ""}
            nextChapterImage={
              nextChapter?.imgPreview ? nextChapter?.imgPreview : ""
            }
            similarProductions={similarProductions}
            isPodcast={isPodcast}
          />
          <InformationSection
            token={token}
            title={currentProduction.title}
            description={currentProduction.description}
            onClick={handleSaveProduction}
            isProductionSaved={isProductionSaved}
            isProductionRented={isProductionRented}
            isSubscriptionActive={userSubscription?.stripeStatus === "active"}
            price={currentProduction.price}
            navigateToRental={handleCallToActionRental}
            onSubmitRating={onSubmitRating}
            onCancelRating={onCancelRating}
            onClickRatingButton={onClickRatingButton}
            showModalRating={showModalRating}
            userCanComment={userCanComment}
          />
          <div className="StyledSubscribedContent relative pb-36 bg-secondary-300">
            {userSubscription?.stripeStatus !== "active" &&
              !isProductionRented && <StyledBlurWall />}
            {!!(
              currentProduction?.seasons?.length ||
              currentProduction?.chapters?.length
            ) && (
              <SeasonsSection
                onChapterClick={onGetChapter}
                seasons={currentProduction.seasons}
                chapters={currentProduction.chapters}
                currentChapter={currentChapter}
              />
            )}
            <RelatedAreas terms={currentProduction.terms} />
            {/* <RelatedContent
        categoryName="MÁS TÍTULOS SOBRE ESTA SOFT SKILL:"
        productions={dataSliders}
      /> */}

            {!!comments?.items?.length && (
              <UsersCommentsSection
                comments={comments}
                handleChangeCommentsPage={handleChangeCommentsPage}
              />
            )}
            {!token && (
              <StyledButton onClick={() => navigateTo("/signup")}>
                ¡Regístrate ahora!
              </StyledButton>
            )}
          </div>
        </div>
      </StyledModal>
    </DefaultLayout>
  );
};
