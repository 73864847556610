import { LearningPathService } from "../../services/learningPath.service";
import { handleResponse } from "../../shared";

export function useCaseLearningPath(service: LearningPathService) {
    return {
        getListMedals({ success, error }: handleResponse) {
            return service.getListMedals({ success, error });
        },
        getlistLearningPathsToRentToRent({ success, error }: handleResponse) {
            return service.getlistLearningPathsToRentToRent({ success, error });
        },
        getlistOfRentedLearningPaths({ success, error }: handleResponse) {
            return service.getlistOfRentedLearningPaths({ success, error });
        }
    }
}