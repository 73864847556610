import { FC, useState } from "react";
import {
  AccordionMedal,
  Breadcrumb,
  Button,
  MedalCard,
  Modal,
} from "../../components";
import { DefaultLayout } from "../../layouts";
import { StyledLearningPathDetailContainer } from "./learningPathDetail.styles";
import { ModalsLearningPath } from "./components/modals";
import { useLearningPathDetailViewModel } from "../../viewModels";
import { Tooltip } from "@mui/material";
import { ReactComponent as InformationTooltipIcon } from "../../assets/images/informationCircleForTooltip.svg";
import { StyledBlurWall, StyledButton } from "../preview/preview.styles";
import {
  InformationSection,
  RelatedAreas,
  SeasonsSection,
  UsersCommentsSection,
  VideoPreview,
} from "../preview/components";
import LearningPathProduction from "../home/components/LearningPaths/LearningPathsProduction/learningPathsProduction";
import { deflateRawSync } from "zlib";

const breadcrumbItemsElements = [
  {
    name: "Inicio",
    url: "/",
  },
  {
    name: "Caminos de Aprendizaje",
    url: "/learning-path",
  },
  {
    name: "Camino",
  },
];

export const LearningPathDetail: FC = () => {
  const {
    token,
    sizeScreen,
    videoOptions,
    detailLearningPath,
    detailGoldMedal,
    handleStartLearningPath,
    showLogInModal,
    setShowLoginModal,
    setShowStartPathModal,
    showStartPathModal,
    showSuccessModal,
    navigateTo,
    handleRedirectToFirstProduction,
    handleNotSubscribedModal,
    showNotSubscribedModal,
    handleStartPath,
    showTransactionModal,
    handleTransactionModal,
    transactionFinalStatus,
    currentProduction,
    handleNewVideoSource,
    changeProduction,
    nextChapter,
    currentChapter,
    handlePlayerReady,
    onChapterClick,
    similarProductions,
    videoTimeLeft,
    userSubscription,
    handleSaveProduction,
    isProductionSaved,
    onCancelRating,
    onClickRatingButton,
    showModalRating,
    onSubmitRating,
    userCanComment,
    productionOptions,
    currentProductionNumber,
  } = useLearningPathDetailViewModel();
  console.log(currentProduction);
  console.log(detailLearningPath);
  return (
    <DefaultLayout>
      <div className="px-8 pb-8 lg:px-14 hidden lg:block">
        <Breadcrumb breadcrumbItems={breadcrumbItemsElements} />
      </div>
      <StyledLearningPathDetailContainer>
        <VideoPreview
          videoOptions={videoOptions}
          imgPreview={currentChapter ? currentChapter?.imgPreview : ""}
          format={currentProduction ? currentProduction.productionType : ""}
          terms={currentProduction ? currentProduction.terms : []}
          duration={currentProduction ? currentProduction.duration : 0}
          handlePlayerReady={handlePlayerReady}
          timeLeft={videoTimeLeft}
          chapterName={currentChapter ? currentChapter.name : ""}
          productionName={
            currentProduction
              ? currentProduction.title
              : detailLearningPath.title
          }
          currentSeasonNumber={1}
          nextChapterName={nextChapter?.name ? nextChapter.name : ""}
          nextChapterImage={
            nextChapter?.imgPreview ? nextChapter.imgPreview : ""
          }
          similarProductions={similarProductions}
        />

        <InformationSection
          token={token}
          title={
            currentChapter.name
              ? currentChapter.name
              : currentProduction
              ? currentProduction.title
              : detailLearningPath.title
          }
          description={
            currentChapter
              ? currentChapter.description
              : currentProduction.description
          }
          onClick={handleSaveProduction}
          isProductionSaved={isProductionSaved}
          isProductionRented={detailLearningPath.canWatch}
          isSubscriptionActive={detailLearningPath.canWatch}
          price={detailLearningPath.price}
          navigateToRental={() => {
            handleStartPath();
          }}
          onSubmitRating={onSubmitRating}
          onCancelRating={onCancelRating}
          onClickRatingButton={onClickRatingButton}
          showModalRating={showModalRating}
          userCanComment={userCanComment}
          detailLearningPath={detailLearningPath}
        />
        <div className="StyledSubscribedContent relative pb-36 bg-secondary-300">
          {!detailLearningPath.canWatch && <StyledBlurWall />}
          {detailLearningPath.productions && (
            <LearningPathProduction
              currentProductionNumber={currentProductionNumber}
              productionOptions={productionOptions}
              onChapterClick={onChapterClick}
              production={currentProduction}
              title={detailLearningPath.title}
              canWatch={detailLearningPath.canWatch}
              uuid={detailLearningPath.uuid}
              image={detailLearningPath.image}
              changeProduction={changeProduction}
            />
          )}

          {!token && (
            <div>
              {/* <StyledButton onClick={() => navigateTo("/signup")}>
                ¡Regístrate ahora!
              </StyledButton> */}
            </div>
          )}
        </div>
      </StyledLearningPathDetailContainer>
      <ModalsLearningPath
        showLogInModal={showLogInModal}
        showStartPathModal={showStartPathModal}
        setShowStartPathModal={setShowStartPathModal}
        handleStartLearningPath={handleStartLearningPath}
        learningPathId={detailLearningPath.uuid}
        learningPathTitle={detailLearningPath.title}
        medalImage={detailGoldMedal ? detailGoldMedal.imgPreview : ""}
        setShowLoginModal={setShowLoginModal}
        showSuccessModal={showSuccessModal}
        handleRedirectToFirstProduction={handleRedirectToFirstProduction}
        handleNotSubscribedModal={handleNotSubscribedModal}
        showNotSubscribedModal={showNotSubscribedModal}
        showTransactionModal={showTransactionModal}
        handleTransactionModal={handleTransactionModal}
        transactionFinalStatus={transactionFinalStatus}
      />
    </DefaultLayout>
  );
};
