import { FC } from "react";
import { SelectProps as SelectPropsMUI } from "@mui/material";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import {
  StyledSelect,
  StyledMenuItem,
  StyledHelperText,
} from "./Select.styles";
import { OptionsSelectEntity } from "../../../domain/entities";
import { ArrowDownIcon } from "../../assets/Icons";

export interface SelectProps extends SelectPropsMUI {
  id?: string;
  name?: string;
  label?: string;
  error?: boolean;
  helperText?:
    | string
    | FieldError
    | Partial<{ type: string | number; message: string }>
    | Merge<FieldError, FieldErrorsImpl<any>>;
  iconSrc?: string;
  placeholder?: string;
  options: Array<OptionsSelectEntity>;
  selectColor?: "primary" | "secondary";
}

export const Select: FC<SelectProps> = (props) => {
  const {
    className,
    id,
    name,
    label,
    error,
    helperText,
    placeholder,
    options,
    required,
    value,
    inputRef,
    selectColor = "primary",
    ...rest
  } = props;
  // const classes = useStyles();
  return (
    <div
      className={`relative flex flex-col w-full gap-y-1 text-left ${className}`}
    >
      <label htmlFor={id} className="text-white">
        {label}
        {!!required && <span className="text-primary-100"> *</span>}
      </label>
      <StyledSelect
        id={id}
        name={name}
        inputRef={inputRef}
        placeholder={placeholder}
        value={value}
        {...rest}
        MenuProps={{
          sx: {
            ul: {
              padding: "16px 0",
              background: `${
                selectColor === "primary" ? `#434343` : `#FFFFFF`
              }`,
              borderRadius: "8px",
            },
            li: {
              borderRadius: "0",
            },

            "& .MuiPaper-root": {
              background: `${
                selectColor === "primary" ? `#434343` : `#FFFFFF`
              }`,
              borderRadius: "8px",
            },
          },
        }}
        selectcolor={selectColor}
        IconComponent={(props) => (
          <ArrowDownIcon
            color={selectColor === "primary" ? `#636363` : `#FFFFFF`}
            className="h-8 w-8 pointer-events-none"
            {...props}
          />
        )}
      >
        {options.map((currentOption, key) => {
          const isPlaceHolder = currentOption.id === "none";
          return (
            <StyledMenuItem
              key={key}
              selectcolor={selectColor}
              value={currentOption.id}
              disabled={isPlaceHolder}
              className={isPlaceHolder ? "text-gray-500" : "text-black"}
            >
              {currentOption.name}
            </StyledMenuItem>
          );
        })}
      </StyledSelect>

      {!!error && !!helperText && (
        <StyledHelperText>{helperText as string}</StyledHelperText>
      )}
    </div>
  );
};
