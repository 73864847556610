import React, { useRef, useState } from "react";
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";
import { useCustomEffect } from "../../hooks/useHookEffect";
import { StyledVideo } from "./video.styles";

interface propsInterface {
  className?: string;
  options: VideoJsPlayerOptions;
  onReady?: (player: VideoJsPlayer) => void;
  nextChapterId?: string;
  similarProductions?: {
    firstProductionId: string;
    secondProductionId: string;
    thirdProductionId: string;
  };
}
export function VideoJS(props: propsInterface) {
  const { className, options, onReady } = props;
  const videoRef = useRef(null);
  const playerRef = useRef<VideoJsPlayer | null>();
  const Button = videojs.getComponent("Button");
  const CloseButton = videojs.getComponent("CloseButton");
  const ControlBar = videojs.getComponent("ControlBar");
  const ClickableComponent = videojs.getComponent("ClickableComponent");
  // const [inactivityTimeout, setInactivityTimeout] = useState<any>(null);
  let inactivityTimeout: any = null;

  let userActivity: any, activityCheck: any;
  useCustomEffect(() => {
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;
      const player = (playerRef.current = videojs(videoElement, options, () => {
        if (onReady) {
          // const modal = new ModalDialog(player, {
          //   content: videoOverlay,
          //   temporary: false,
          //   // pauseOnOpen: false,
          // });
          onReady(player);

          /*child init */
          const titleVideo = new ClickableComponent(player);
          const controlBarForNewButtons = new ControlBar(player, {
            id: "vjs-new-control-bar-id",
          });
          const chapterName = new ClickableComponent(player);
          const closeFullScreenButton = new CloseButton(player);
          const chaptersButton = new Button(player);
          const nextChapterCard = new ClickableComponent(player);
          const nextChapterButton = new Button(player);
          const forwardButton = new Button(player);
          const delayButton = new Button(player);
          const similarProductionsContainer = new ClickableComponent(player);
          const firstProduction = new ClickableComponent(player);
          const secondProduction = new ClickableComponent(player);
          const thirdProduction = new ClickableComponent(player);

          /*child attrs*/
          titleVideo.addClass("vjs-title-video");
          controlBarForNewButtons.addClass("vjs-new-control-bar");
          controlBarForNewButtons.setAttribute("id", "vjs-new-control-bar-id");
          chapterName.addClass("vjs-new-chapter-name");
          closeFullScreenButton.addClass("vjs-close-full-screen-button");
          closeFullScreenButton.controlText("Salir de pantalla completa");
          chaptersButton.addClass("vjs-chapters-button");
          chaptersButton.controlText("Ver capítulos");
          nextChapterCard.addClass("vjs-next-chapter-card");
          nextChapterCard.controlText("Siguiente Capítulo");
          nextChapterCard.setAttribute("id", "vjs-next-chapter-card-id");
          nextChapterButton.addClass("vjs-next-chapter-button");
          nextChapterButton.controlText("Siguiente Capítulo");
          nextChapterButton.setAttribute("id", "vjs-next-chapter-button-id");
          forwardButton.addClass("vjs-forward-button");
          delayButton.addClass("vjs-delay-button");
          similarProductionsContainer.addClass(
            "vjs-similar-productions-container"
          );
          firstProduction.addClass("vjs-first-production");
          firstProduction.setAttribute("id", "vjs-first-production-id");
          secondProduction.addClass("vjs-second-production");
          secondProduction.setAttribute("id", "vjs-second-production-id");
          thirdProduction.addClass("vjs-third-production");
          thirdProduction.setAttribute("id", "vjs-third-production-id");

          /*Paring child*/
          player.controlBar.addChild(titleVideo);
          player.controlBar.addChild(closeFullScreenButton);
          player.controlBar.addChild(chapterName);
          player.controlBar.addChild(forwardButton);
          player.controlBar.addChild(delayButton);
          player.controlBar.addChild(nextChapterCard);
          player.controlBar.addChild(similarProductionsContainer);
          player.controlBar.getChild("CurrentTimeDisplay")?.show();
          player.controlBar.getChild("RemainingTimeDisplay")?.hide();
          similarProductionsContainer.addChild(firstProduction);
          similarProductionsContainer.addChild(secondProduction);
          similarProductionsContainer.addChild(thirdProduction);
          nextChapterCard.hide();
          closeFullScreenButton.hide();
          player.addChild(controlBarForNewButtons);
          controlBarForNewButtons.hide();
          controlBarForNewButtons.addChild(chaptersButton);
          controlBarForNewButtons.addChild(nextChapterButton);
          controlBarForNewButtons.getChild("PlayToggle")?.hide();
          controlBarForNewButtons.getChild("ProgressControl")?.hide();
          controlBarForNewButtons.getChild("VolumePanel")?.hide();
          controlBarForNewButtons.getChild("RemainingTimeDisplay")?.hide();
          controlBarForNewButtons.getChild("CurrentTimeDisplay")?.hide();

          /*child Events*/
          closeFullScreenButton.on("click", () => {
            player.isFullscreen() && player.exitFullscreen();
          });
          closeFullScreenButton.on("tap", () => {
            player.isFullscreen() && player.exitFullscreen();
          });

          chaptersButton.on("click", () => {
            player.isFullscreen() && player.exitFullscreen();
            setTimeout(
              () => document.getElementById("SeasonsSection")?.scrollIntoView(),
              200
            );
          });
          chaptersButton.on("tap", () => {
            player.isFullscreen() && player.exitFullscreen();
            setTimeout(
              () => document.getElementById("SeasonsSection")?.scrollIntoView(),
              500
            );
          });

          forwardButton.on("click", () => {
            const currentTime = player.currentTime();
            player.currentTime(currentTime + 10);
          });
          forwardButton.on("tap", () => {
            const currentTime = player.currentTime();
            player.currentTime(currentTime + 10);
          });

          delayButton.on("click", () => {
            const currentTime = player.currentTime();
            player.currentTime(currentTime - 10);
          });
          delayButton.on("tap", () => {
            const currentTime = player.currentTime();
            player.currentTime(currentTime - 10);
          });

          /*Player events*/
          player.on("mousemove", function () {
            userActivity = true;
          });

          activityCheck = setInterval(function () {
            // Check to see if the mouse has been moved
            if (userActivity) {
              // Reset the activity tracker
              userActivity = false;

              // If the user state was inactive, set the state to active
              if (player.userActive() === false) {
                player.userActive(true);
              }

              // Clear any existing inactivity timeout to start the timer over
              clearTimeout(inactivityTimeout);

              // In X seconds, if no more activity has occurred
              // the user will be considered inactive
              inactivityTimeout = setTimeout(function () {
                // Protect against the case where the inactivity timeout can trigger
                // before the next user activity is picked up  by the
                // activityCheck loop.
                if (!userActivity) {
                  player.userActive(false);
                }
              }, 2000);
            }
          }, 250);

          player.on("posterchange", () => {
            controlBarForNewButtons.hide();
            nextChapterCard.hide();
            similarProductionsContainer.hide();
          });

          player.on("ended", () => {
            nextChapterCard.show();
            similarProductionsContainer.show();
            forwardButton.hide();
          });
          player.on("useractive", () => {
            // player.userActive(true);
            if (inactivityTimeout != null) {
              clearTimeout(inactivityTimeout);
            }
            inactivityTimeout = setTimeout(function () {
              player.userActive(false);
            }, 3000);
          });
          player.on("timeupdate", () => {
            forwardButton.show();
            nextChapterCard.hide();
            similarProductionsContainer.hide();
          });
          player.on("play", () => {
            nextChapterCard.hide();
            similarProductionsContainer.hide();
            if (!player.isFullscreen()) {
              player.controlBar.removeClass("vjs-important-fullscreen");
              controlBarForNewButtons.removeClass("vjs-important-fullscreen");
            }
          });

          player.on("pause", () => {});

          player.on("playing", function () {
            controlBarForNewButtons.show();
            forwardButton.show();
            nextChapterCard.hide();
            similarProductionsContainer.hide();
          });

          player.on("fullscreenchange", () => {
            if (player.isFullscreen()) {
              closeFullScreenButton.show();
              player.controlBar.addClass("vjs-important-fullscreen");
              controlBarForNewButtons.addClass("vjs-important-fullscreen");
            } else {
              closeFullScreenButton.hide();
              player.controlBar.removeClass("vjs-important-fullscreen");
              controlBarForNewButtons.removeClass("vjs-important-fullscreen");
            }
          });
        }
      }));
    } else {
      const player = playerRef.current;
      options.sources && player.src(options.sources);
      options.poster && player.poster(options.poster);
    }
  }, [options, videoRef]);

  useCustomEffect(
    () => {},
    [playerRef],
    () => {
      if (!playerRef.current || playerRef.current?.isDisposed()) {
        playerRef.current?.dispose();
        playerRef.current = null;
      }
    }
  );

  return (
    <StyledVideo
      ref={videoRef}
      className={`StyledVideo video-js vjs-big-play-centered  ${className}`}
    />
  );
}
