export enum Endpoints {
  // Auth endpoints
  login = "auth/login",
  logout = "auth/logout",
  forgotPassword = "auth/forgot-password",
  resetPassword = "auth/reset-password",
  profile = "auth/profile",
  notifications = "auth/notifications",

  //general
  countries = "countries",

  //catalogues
  catalogues = "catalogs",

  //content
  content = "pages",

  //coupons
  coupons = "redeem-coupon",

  //Faqs
  faqs = "faqs",
  contactForm = "contact-form",
  contactFormMatter = "taxonomies/form_contact_matter/terms",
  contactFormProductMatter = "taxonomies/form_contact_product_matter/terms",

  // home
  homeData = "variables?filter[id]=home&format=undot",
  homeProductions = "home-productions",

  // legal
  legalData = "variables?filter[id]=legal&format=undot",

  //Productions
  productions = "productions",
  chapters = "chapters",
  terms = "terms",
  games = "games",

  //rentals
  rentals = "productions-rent",
  formats = "productions-type",

  //Subscriptions
  subscriptionsList = "suscriptions",
  subscriptionCheckout = "suscription-checkout",
  billingPortal = "billing-portal",

  // user
  users = "users",

  //Dashboard
  savedProductions = "user-production",
  productionsInProgress = "my-story-training",
  chapterProgress = "chapter-progress",
  userMedals = "user-medals",
  progressByTerms = "progress-by-terms",

  //Search
  search = "search",

  //SEO
  seo = "seo",

  //LearningPaths
  listMedals = "medals",
  listLearningPathsToRent = "learning-paths",
  rentLearningPaths = "learning-paths/rent"
}
