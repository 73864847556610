import tw from "twin.macro";
import styled from "styled-components";

export interface StyledSectionProps {
  withoutPadding?: boolean;
}

export const StyledPageNotFound = styled.div.attrs({ className: "StyledPageNotFound" })`
${tw`
    text-white
`}

h1 {
    ${tw`font-primaryBold text-3xl `}
}
h2 {
    ${tw`font-primaryBold text-3xl`}
}
span {
    ${tw`text-primary-100`}
}
p{
    ${tw`mx-4`}
}
`;

export const StyledSection = styled.section.attrs({
  className: "StyledSection",
}) <StyledSectionProps>`
    ${tw`relative w-full py-16 px-5 flex flex-col text-center items-center`}
  
    > h2, > article > h2 {
      ${tw`text-xl leading-9 font-primaryBold text-white sm:(text-[2rem] leading-[2.375rem])`}
      span {
        ${tw`text-primary-100`}
      }
    }
  
    > h3 {
      ${tw`text-sm font-primaryBold text-white sm:(text-2xl)`}
      span {
        ${tw`text-primary-100`}
      }
    }
  
    > p,
    > article > p {
      ${tw`max-w-[67.5rem] text-xl leading-6 text-white`}
    }
    ${({ withoutPadding }) => withoutPadding && tw`px-0`}
  `;
