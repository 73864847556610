import tw from "twin.macro";
import styled from "styled-components";
import { Button } from "../../../../components";
export const StyledInformationSection = styled.div.attrs({
  className: "StyledInformationSection",
})`
  ${tw`
   bg-secondary-300
   text-white
   py-10
   px-4
    mx-auto
    w-full
  max-w-preview

 `}
  h1 {
    ${tw`lg:max-w-[80%] mt-0`}
  }
`;

export const StyledButton = styled(Button).attrs({
  className: "StyledButton",
})`
  ${tw`
  w-60
  hidden
  lg:inline-flex
  
 `}
`;

export const StyledButtonRent = styled(Button).attrs({
  className: "StyledButtonRent",
  color: "secondary",
  colorText: "#E30613",
})`
  ${tw`
  w-48 p-0
  
 `}
`;
