import { ChangeEvent, useRef, useState } from "react";
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";
import {
  ProductionCommentsModel,
  ProductionModel,
  ProductionSeoModel,
} from "../../../data/models";
import {
  productionsDataService,
  dashboardDataService,
  subscriptionsDataService,
  commentsDataService,
} from "../../../data/services";
import {
  useCaseComments,
  useCaseProductions,
  useCaseRentals,
  useCaseDashboard,
} from "../../../domain/useCases";
import { useCustomEffect, useNavigation } from "../../hooks";
import { useSelector } from "react-redux";
import {
  getSessionToken,
  getSubscriptionStatus,
} from "../../../data/dto/selectors/session.selector";
import {
  ChapterEntity,
  PreviewChapterEntity,
  ProductionEntity,
  SeasonEntity,
} from "../../../domain/entities";
import toast from "react-hot-toast";
import { rentalsDataService } from "../../../data/services/rentals.data.service";
import { FieldValues } from "react-hook-form";

export function usePreviewViewModel() {
  const [showModalPreview, setShowModalPreview] = useState(false);
  const [showModalRating, setShowModalRating] = useState(false);
  const [currentProduction, setCurrentProduction] = useState<ProductionModel>(
    {} as ProductionModel
  );
  const [currentChapter, setCurrentChapter] = useState<ChapterEntity>(
    {} as ChapterEntity
  );
  const [similarProductions, setSimilarProductions] = useState<
    Array<ProductionEntity> | []
  >([]);

  const [nextChapter, setNextChapter] = useState<Partial<PreviewChapterEntity>>(
    {} as PreviewChapterEntity
  );
  const [seoProductionData, setSeoProductionData] =
    useState<ProductionSeoModel>({
      title: "",
      description: "",
      image: "",
      author: "",
      robots: "",
      jsonLd: "",
      facebookAppId: "",
      twitterSite: "",
    });
  const [videoOptions, setVideoOptions] = useState<VideoJsPlayerOptions>({
    controls: true,
    controlBar: {
      pictureInPictureToggle: false,
      volumePanel: false,
      fullscreenToggle: false,
      currentTimeDisplay: true,
    },
    responsive: true,
    fluid: true,
  });

  const [isProductionSaved, setIsProductionSaved] = useState(false);
  const [userCanComment, setUserCanComment] = useState(false);
  const [isProductionRented, setIsProductionRented] = useState(false);
  const [videoTimeLeft, setVideoTimeLeft] = useState<number>(0);
  const [currentSeasonNumber, setCurrentSeasonNumber] = useState<number>(0);
  const [videoPlayerCurrentTime, setVideoPlayerCurrentTime] =
    useState<number>(0);
  const [isPodcast, setIsPodcast] = useState(false);
  const [comments, setComments] = useState<ProductionCommentsModel>(
    {} as ProductionCommentsModel
  );
  const [filterComments, setFilterComments] = useState({
    limit: 4,
    page: 1,
  });

  const playerRef = useRef<VideoJsPlayer | null>(null);
  const { navigateTo, getQueryParam, getCurrentPathName } = useNavigation();
  const { getById, getSeo, getChapter, getByCategory } = useCaseProductions(
    productionsDataService()
  );
  const { getRental } = useCaseRentals(rentalsDataService());

  const { postSavedProductionDashboard, postChapterProgressDashboard } =
    useCaseDashboard(dashboardDataService());
  const { getComments, rateProduction } = useCaseComments(
    commentsDataService()
  );

  const token = useSelector(getSessionToken);
  const userSubscription = useSelector(getSubscriptionStatus);

  /**Video handlers */
  const handleNewVideoSource = (source: string, poster?: string) => {

    if (source) {
      setIsPodcast(false);
      setVideoOptions({
        ...videoOptions,
        autoplay: "play",
        sources: [
          {
            src: source,
            type: "application/x-mpegURL",
          },
        ],
        poster: poster ? poster : videoOptions.poster,
      });
    } else {

      setVideoOptions({
        ...videoOptions,
        poster: poster ? poster : videoOptions.poster,
      });
    }
    window.scrollTo(0, 0);
  };

  useCustomEffect(() => {
    if (currentChapter.uuid && playerRef?.current?.player_) {
      const playerOut = playerRef.current;
      playerOut.ready(function () {
        var promise = playerOut.play();
        if (promise !== undefined) {
          promise
            .then(function () {
              // Autoplay started!
              console.log("Autoplay started");
            })
            .catch(function (error) {
              // Autoplay was prevented.
            });
        }
      });
      playerOut.currentTime(currentChapter.progress);
    }
  }, [currentChapter]);

  /*Handlers preview chapters progress */

  // handler to find the current chapter and change his progress
  const handleChapterProgress = (
    chapterData: PreviewChapterEntity,
    currentChapter: PreviewChapterEntity
  ) => {
    if (currentChapter.uuid === chapterData.uuid) {
      // return chapter with the new progress
      return { ...chapterData, progress: Math.floor(videoPlayerCurrentTime) };
    } else {
      return chapterData;
    }
  };

  // handle all chapters and seasons
  const handleFindCurrentChapterProgress = () => {
    let chaptersAfterNewProgress: PreviewChapterEntity[] | [] = [];
    let seasonsChaptersAfterNewProgress: SeasonEntity[] | [] = [];

    // mapping all chapters to find the current chapter, and change his progress
    if (currentProduction.chapters.length) {
      chaptersAfterNewProgress = currentProduction.chapters.map((chapter) =>
        handleChapterProgress(chapter, currentChapter)
      );
    }

    // mapping all seasons to find the current chapter, and change his progress
    if (currentProduction.seasons.length) {
      seasonsChaptersAfterNewProgress = currentProduction.seasons.map(
        (currentSeason) => {
          // mapping the current seasons chapter to find the current chapter and change his progress
          const newSeasonChaptersAfterProgress = currentSeason.chapters.map(
            (chapter) => handleChapterProgress(chapter, currentChapter)
          );
          return {
            ...currentSeason,
            chapters: newSeasonChaptersAfterProgress,
          };
        }
      );
    }

    const newProductionAfterNewProgress = {
      ...currentProduction,
      chapters: chaptersAfterNewProgress,
      seasons: seasonsChaptersAfterNewProgress,
    };

    setCurrentProduction(newProductionAfterNewProgress);
  };

  const handleAllTimeRangesArray = (previewsTimeRanges: number[][]) => {
    const playerOut = playerRef.current;
    const timeRangesLength = playerOut?.played().length
      ? playerOut.played().length
      : 0;
    let currentTimeRanges = [[0, 1]];

    // create current Time Ranges array
    if (timeRangesLength) {
      for (let index = 0; index < timeRangesLength; index++) {
        const currentTimeRange = [
          playerOut?.played().start(index) as number,
          playerOut?.played().end(index) as number,
        ];
        currentTimeRange.length && currentTimeRanges.push(currentTimeRange);
      }
    }

    // Sort the time ranges by the start time
    const finalTimeRangeArray = currentTimeRanges.length
      ? [...currentTimeRanges, ...previewsTimeRanges].sort(
        (a, b) => a[0] - b[0]
      )
      : previewsTimeRanges.sort((a, b) => a[0] - b[0]);

    let finalTimeRangeArraySummary = [];

    //final iteration
    if (finalTimeRangeArray.length > 1 && finalTimeRangeArray[0].length > 1) {
      let previousRange = finalTimeRangeArray[0];

      // Iterate over the sorted time ranges
      for (let index = 1; index < finalTimeRangeArray.length; index++) {
        const currentRange = finalTimeRangeArray[index];

        // If the current range overlaps with the previous range, merge them
        if (currentRange[0] <= previousRange[1]) {
          previousRange = [
            previousRange[0],
            Math.max(previousRange[1], currentRange[1]),
          ];

          // If the current range does not overlap with the previous range, add the previous range to the result array
        } else {
          finalTimeRangeArraySummary.push(previousRange);
          previousRange = currentRange;
        }
      }
      // Add the last previous range to the result array
      finalTimeRangeArraySummary.push(previousRange);
    }
    return {
      timeRanges: finalTimeRangeArraySummary,
    };
  };

  useCustomEffect(() => {
    if (currentChapter.uuid) {
      const timeRangesChapter = currentChapter.timeRanges
        ? currentChapter.timeRanges
        : [[0, 1]];
      const { timeRanges } = handleAllTimeRangesArray(timeRangesChapter);
      handleFindCurrentChapterProgress();
      postChapterProgressDashboard({
        progress: Math.floor(videoPlayerCurrentTime),
        chapterUuid: currentChapter.uuid,
        timeRanges,
      });
    }
  }, [videoPlayerCurrentTime]);

  const handlePlayerReady = (player: VideoJsPlayer) => {
    let intervalProgress: number;
    let intervalCurrentTime: number;
    playerRef.current = player;
    if (userSubscription?.stripeStatus !== "active") {
      player.controlBar.addClass("vjs-hidden-on-unsubscribe");
      player
        .getChildById("vjs-new-control-bar-id")
        ?.addClass("vjs-hidden-on-unsubscribe");
    } else {
      player.controlBar.removeClass("vjs-hidden-on-unsubscribe");
      player
        .getChildById("vjs-new-control-bar-id")
        ?.addClass("vjs-hidden-on-unsubscribe");
    }
    // You can handle player events here, for example:
    player.on("pause", () => {
      const currentTime = player.currentTime();
      const timeLeft = player.duration() - player.currentTime();
      setVideoPlayerCurrentTime(currentTime);
      setVideoTimeLeft(timeLeft);
      player.clearInterval(intervalProgress);
      player.clearInterval(intervalCurrentTime);
    });

    player.on("play", () => {
      if (userSubscription?.stripeStatus !== "active") {
        player.controlBar.addClass("vjs-hidden-on-unsubscribe");
        player
          .getChildById("vjs-new-control-bar-id")
          ?.addClass("vjs-hidden-on-unsubscribe");
      } else {
        player.controlBar.removeClass("vjs-hidden-on-unsubscribe");
        player
          .getChildById("vjs-new-control-bar-id")
          ?.removeClass("vjs-hidden-on-unsubscribe");
      }

      intervalProgress = player.setInterval(() => {
        const currentTime = player.currentTime();
        setVideoPlayerCurrentTime(currentTime);
      }, 5000);
    });

    player.on("dispose", () => {
      const currentTime = player.currentTime();
      setVideoPlayerCurrentTime(currentTime);
      player.clearInterval(intervalProgress);
    });

    player.on("ended", () => {
      const currentTime = player.currentTime();
      setVideoPlayerCurrentTime(currentTime);
      player.clearInterval(intervalProgress);
    });

    player.on("timeupdate", () => {
      const timeLeft = player.duration() - player.currentTime();
      setVideoTimeLeft(timeLeft);
    });
  };

  useCustomEffect(() => {
    if (currentChapter.uuid && currentProduction.seasons.length) {
      const indexCurrentSeasonChapter = currentProduction.seasons.findIndex(
        (currentSeason) =>
          currentSeason.chapters.find(
            (currentChapterSeason) =>
              currentChapterSeason.uuid === currentChapter.uuid
          )
      );
      setCurrentSeasonNumber(indexCurrentSeasonChapter + 1);
    }
  }, [currentChapter, currentProduction]);

  /*Production Handlers */
  const getChapterSuccess = (chapter: ChapterEntity) => {
    // if (playerRef?.current?.player_) {
    //   // Force dispose on unmount for send current progress
    //   const playerOut = playerRef.current;
    //   playerOut.pause(); 
    // }
    handleNewVideoSource(chapter.videoUrl, chapter.imgPreview);
    setCurrentChapter(chapter);
    setNextChapter(chapter.nextChapter ? chapter.nextChapter : {});
  };

  const getChapterError = (error: any) => {
    console.log({ error });
    // toast.error(error.response.data.message);
  };

  // SEO Production
  const handleSuccessProductionSEO = (data: ProductionSeoModel) => {
    const seoDataToSet: ProductionSeoModel = {
      title: data.title ? data.title : currentProduction.title,
      description: data.description
        ? data.description
        : currentProduction.description,
      image: data.image ? data.image : currentProduction.imgPreview,
      author: data.author,
      robots: data.robots,
      jsonLd: data.jsonLd,
      facebookAppId: data.facebookAppId,
      twitterSite: data.twitterSite,
    };
    setSeoProductionData(seoDataToSet);
  };

  const handleGetProductionSEO = (id: string) => {
    getSeo({ id, success: handleSuccessProductionSEO });
  };

  // ***************First render: get production*************
  const onGetProductionSuccess = (production: ProductionModel) => {
    handleGetProductionSEO(production.uuid);
    const chapterId = getQueryParam("chapterUuid");
    production.terms[0]?.uuid &&
      getByCategory({
        id: production.terms[0].uuid,
        success: (categoryProductions) =>
          setSimilarProductions(categoryProductions),
      });

    if (production.format === "Podcast") {
      setIsPodcast(true);
      if (playerRef?.current?.player_) {
        const playerOut = playerRef.current;
        playerOut
          .getChildById("vjs-new-control-bar-id")
          ?.addClass("vjs-hidden-on-podcast");
      }
    } else {
      setIsPodcast(false);
      if (playerRef?.current?.player_) {
        const playerOut = playerRef.current;
        playerOut
          .getChildById("vjs-new-control-bar-id")
          ?.removeClass("vjs-hidden-on-podcast");
      }
    }

    setCurrentProduction(production);
    setIsProductionSaved(production.saved);
    setShowModalPreview(true);

    if (token && chapterId) {
      onGetChapter(chapterId);
    } else {
      handleNewVideoSource(production.videoPreview, production.imgPreview);
      if (production.seasons.length) {
        setNextChapter(production.seasons[0].chapters[0]);
      }
      if (production.chapters.length) {
        setNextChapter(production.chapters[0]);
      }
    }
  };

  const handleSaveProduction = () => {
    postSavedProductionDashboard({
      productionUuid: currentProduction.uuid,
      success: () => setIsProductionSaved(true),
    });
  };

  const onGetChapter = (id: string, currentSeason?: number) => {
    currentSeason && setCurrentSeasonNumber(currentSeason);
    getChapter({
      id,
      success: getChapterSuccess,
      error: getChapterError,
    });
  };

  /*Modal Preview Handlers */
  const handleModalClose = () => {
    const currentPathName = getCurrentPathName();
    setShowModalPreview(false);
    navigateTo(currentPathName);
    if (playerRef?.current?.player_) {
      // Force dispose on unmount for send current progress
      const playerOut = playerRef.current;
      playerOut.pause();
    }
    setCurrentChapter({} as ChapterEntity);
    setCurrentProduction({} as ProductionModel);
  };

  const handleCallToActionRental = () => {
    getRental({
      productionId: currentProduction.uuid,
      success: (url) => {
        window.location.replace(url);
      },
    });
  };

  const handleRedirectOnResize = () => {
    const currentPathName = getCurrentPathName();
    const productionId = getQueryParam("uuid");
    const chapterId = getQueryParam("chapterUuid");
    setFilterComments({
      ...filterComments,
      limit: window.innerWidth < 1036 ? 2 : 4,
    });
    if (
      window.innerWidth < 1036 &&
      productionId &&
      (currentPathName === "/catalog" || currentPathName === "/rentals")
    ) {
      navigateTo(
        `/preview?uuid=${productionId}${chapterId || currentChapter.uuid
          ? `&chapterUuid=${chapterId || currentChapter.uuid}`
          : ""
        }`
      );
      window.removeEventListener("resize", handleRedirectOnResize);
    }
  };

  useCustomEffect(() => {
    setIsProductionRented(false);
    !!currentProduction.endsAt
      ? setIsProductionRented(true)
      : setIsProductionRented(false);

    setUserCanComment(currentProduction.canComment);
  }, [currentProduction]);

  useCustomEffect(
    () => {
      window.addEventListener("resize", handleRedirectOnResize);
    },
    [],
    () => {
      window.removeEventListener("resize", handleRedirectOnResize);
    }
  );

  useCustomEffect(() => {
    const currentPathName = getCurrentPathName();
    const productionId = getQueryParam("uuid");
    const chapterId = getQueryParam("chapterUuid");

    if (
      window.innerWidth < 1036 &&
      productionId &&
      (currentPathName === "/catalog" || currentPathName === "/rentals")
    ) {
      navigateTo(
        `/preview?uuid=${productionId}${chapterId ? `&chapterUuid=${chapterId}` : ""
        }`
      );
    }

    if (
      window.innerWidth >= 1036 &&
      currentPathName !== "/catalog" &&
      currentPathName !== "/rentals" &&
      currentPathName !== "/preview"
    ) {
      navigateTo(
        `/catalog?uuid=${productionId}${chapterId ? `&chapterUuid=${chapterId}` : ""
        }`
      );
    }

    if (productionId) {
      setShowModalPreview(false);
      const getProductionData = {
        id: productionId,
        success: onGetProductionSuccess,
        error: () => {
          currentPathName !== "/catalog" &&
            currentPathName !== "/rentals" &&
            currentPathName !== "/preview" &&
            navigateTo("/");
        },
      };
      getById(getProductionData);
    } else {
      currentPathName !== "/catalog" &&
        currentPathName !== "/rentals" &&
        currentPathName !== "/preview" &&
        navigateTo("/");
    }
  }, [getQueryParam("uuid")]);

  const firstProductionSelected = document.getElementById(
    "vjs-first-production-id"
  );
  if (firstProductionSelected != null && similarProductions[0]?.uuid) {
    firstProductionSelected.onclick = function () {
      window.location.href = `/preview?uuid=${similarProductions[0].uuid}`;
    };
    firstProductionSelected.ontouchend = function () {
      window.location.href = `/preview?uuid=${similarProductions[0].uuid}`;
    };
  }

  const secondProductionSelected = document.getElementById(
    "vjs-second-production-id"
  );
  if (secondProductionSelected != null && similarProductions[1]?.uuid) {
    secondProductionSelected.onclick = function () {
      window.location.href = `/preview?uuid=${similarProductions[1].uuid}`;
    };
    secondProductionSelected.ontouchend = function () {
      window.location.href = `/preview?uuid=${similarProductions[1].uuid}`;
    };
  }

  const thirdProductionSelected = document.getElementById(
    "vjs-third-production-id"
  );
  if (thirdProductionSelected != null && similarProductions[0]?.uuid) {
    thirdProductionSelected.onclick = function () {
      window.location.href = `/preview?uuid=${similarProductions[2].uuid}`;
    };
    thirdProductionSelected.ontouchend = function () {
      window.location.href = `/preview?uuid=${similarProductions[2].uuid}`;
    };
  }
  const nextChapterCard = document.getElementById("vjs-next-chapter-card-id");
  if (nextChapterCard != null && nextChapter?.uuid) {
    nextChapterCard.onclick = function () {
      nextChapter.uuid && onGetChapter(nextChapter.uuid);
    };
    nextChapterCard.ontouchend = function () {
      nextChapter.uuid && onGetChapter(nextChapter.uuid);
    };
  }

  const nextChapterButton = document.getElementById(
    "vjs-next-chapter-button-id"
  );
  if (nextChapterButton != null && nextChapter?.uuid) {
    nextChapterButton.onclick = function () {
      nextChapter.uuid && onGetChapter(nextChapter.uuid);
    };
    nextChapterButton.ontouchend = function () {
      nextChapter.uuid && onGetChapter(nextChapter.uuid);
    };
  }

  /******* Modal Rating ********/
  const handlerSuccessRating = () => {
    setUserCanComment(false);
    setShowModalRating(false);
    toast.success("Calificación ralizada con exito");
  };

  const onSubmitRating = (data: FieldValues) => {
    rateProduction({
      productionId: currentProduction.uuid,
      qualification: data.qualification,
      body: data.body,
      success: handlerSuccessRating,
    });
  };

  const onCancelRating = (data: FieldValues) => {
    setShowModalRating(false);
  };

  const onClickRatingButton = () => {
    setShowModalRating(true);
  };

  /******* User Comments ********/
  const handleSuccessCommentsFiltered = (
    commentsData: ProductionCommentsModel
  ) => {
    setComments(commentsData);
  };

  const handleChangeCommentsPage = (
    event: ChangeEvent<unknown>,
    page: number
  ) => {
    setFilterComments({
      ...filterComments,
      page,
    });
  };

  useCustomEffect(() => {
    currentProduction.uuid &&
      getComments({
        filter: filterComments,
        productionId: currentProduction.uuid,
        success: handleSuccessCommentsFiltered,
      });
  }, [filterComments.limit, filterComments.page, currentProduction.uuid]);

  return {
    showModalPreview,
    handleModalClose,

    isProductionRented,
    token,
    userSubscription,
    videoOptions,
    similarProductions,
    currentSeasonNumber,
    currentProduction,
    currentChapter,
    nextChapter,
    onGetChapter,
    navigateTo,
    handleSaveProduction,
    isProductionSaved,
    handlePlayerReady,
    videoTimeLeft,

    handleCallToActionRental,
    isPodcast,

    showModalRating,
    onSubmitRating,
    onCancelRating,
    onClickRatingButton,
    userCanComment,
    comments,
    handleChangeCommentsPage,

    seoProductionData,
  };
}
