import { FC } from "react";
import { StyledCard, StyledImage, StyledImageMobile } from "./Card.styles";
import { IconStar } from "../../assets/Icons";
import { Badge } from "../Badge";
import { FolderIcon, HeartIcon } from "../../assets/Icons";
import { ProductionEntity } from "../../../domain/entities/production.entity";
import { PillEntity } from "../../../domain/entities";
import { useNavigation } from "../../hooks";
import { ReactComponent as PlayButtonIcon } from "../../assets/images/playButtonIcon.svg";
import { Rating } from "../Rating";

export interface CardProps {
  className?: string;
  showOptions?: boolean;
  isCatalog?: boolean;
  withPlayButton?: boolean;
  productionData: ProductionEntity;
}

export const Card: FC<CardProps> = ({
  className,
  showOptions,
  isCatalog,
  withPlayButton,
  productionData,
}: CardProps) => {
  const {
    uuid,
    title,
    description,
    productionType,
    videoPreview,
    imgPreview,
    imgPreviewMobile,
    alt,
    duration,
    terms,
    seasonsCount,
    format,
    qualification,
  } = productionData;
  const { navigateTo, getCurrentPathName } = useNavigation();
  return (
    <StyledCard
      onClick={() => {
        const currentPathName = getCurrentPathName();

        if (window.innerWidth >= 1024 && currentPathName === "/catalog") {
          navigateTo(`/catalog?uuid=${uuid}`);
        } else {
          navigateTo(`/preview?uuid=${uuid}`);
        }
      }}
      withBadge={!!terms?.length}
      className={`StyledCard ${className} cursor-pointer`}
    >
      <div className="relative ">
        {withPlayButton && <PlayButtonIcon className="playButton" />}
        <StyledImage
          src={imgPreview}
          alt={alt ? alt : `Imagen producción ${title}`}
          title={alt ? alt : `Imagen producción ${title}`}
        />
        <StyledImageMobile
          src={imgPreviewMobile ? imgPreviewMobile : imgPreview}
          alt={alt ? alt : `Imagen producción ${title}`}
          title={alt ? alt : `Imagen producción ${title}`}
        />
      </div>

      <div className="flex items-center justify-between">
        <div className=" flex items-end justify-start gap-x-1 my-2 lg:m-0">
          <Rating
            id={"rating"}
            name={"rating"}
            value={+qualification}
            readOnly={true}
          />
          {qualification == 0 && (
            <p className="text-secondary-50 text-xs w-full">Sin calificar</p>
          )}
        </div>
        {showOptions && (
          <div className="flex items-center justify-center gap-x-2">
            <FolderIcon className="folderLogo cursor-pointer" />
            <HeartIcon className="heartLogo cursor-pointer" />
          </div>
        )}
      </div>
      <h3
        className={`uppercase self-center items-center text-white text-center lg:text-left font-primaryBold text-sm ${
          title?.length > 24
            ? title.length > 40
              ? "lg:text-sm"
              : "lg:text-xl"
            : "lg:text-2xl"
        } `}
      >
        {title}
      </h3>
      {!!terms?.length && (
        <div className="top-0 right-2 lg:right-0  absolute lg:relative flex flex-col lg:flex-row items-end lg:items-start lg:flex-wrap gap-1">
          {terms?.map((item: PillEntity, i: number) => {
            return (
              <Badge
                className="Badge capitalize w-fit"
                key={`badge${i}`}
                title={item.title.toLowerCase()}
              />
            );
          })}
        </div>
      )}

      <div
        className={`items-center text-white space-x-2 text-sm lg:text-base font-primarySemiboldItalic hidden lg:flex`}
      >
        <p className="capitalize">
          {productionType == "serie"
            ? seasonsCount > 1
              ? `${seasonsCount} temporadas`
              : `${seasonsCount} temporada`
            : format}
        </p>
      </div>
      <p className=" text-white text-xs lg:text-left text-center lg:text-base font-primaryBook">
        {`${
          isCatalog
            ? description.substring(0, 80)
            : description.substring(0, 120)
        }...`}
        <span className="font-primarySemiBold text-primary"> Ver más</span>
      </p>
    </StyledCard>
  );
};
