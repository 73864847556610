import { FC, useState } from "react";
// import {
//   SeasonEntity,
//   PreviewChapterEntity,
//   ChapterEntity,
// } from "../../../../../domain/entities";
import { StyledButton } from "../../../../preview/preview.styles";
import { ArrowDownIcon, LineSeparatorIcon } from "../../../../../assets/Icons";
import { StyledSelectField } from "../../../../preview/components/SeasonsSection/SeasonsSection.styles";
import { Button, ChapterCard } from "../../../../../components";
import {
  ChapterEntity,
  LearningPathDetailEntity,
} from "../../../../../../domain/entities";
import { LearningPathDetailModel } from "../../../../../../data/models/learningPathDetail.model";
import { LearningPathProductionModel } from "../../../../../../data/models/learningPathProduction.model";
const LearningPathProduction: FC<LearningPathProductionModel> = ({
  production,
  title,
  canWatch,
  uuid,
  image,
  onChapterClick,
  changeProduction,
  productionOptions,
  currentProductionNumber,
}) => {
  console.log(production);
  return (
    <section
      id="SeasonsSection"
      className=" px-4 max-w-preview mx-auto w-full flex flex-col gap-y-12 "
    >
      {!!production && (
        <>
          <ul className="hidden lg:flex">
            {/* {productions.map((currentSeason, index) => {
              return (
                <li className={`flex items-center`} key={index}>
                  {currentSeason?.id !== 0 && <LineSeparatorIcon />}
                  <StyledButton
                    onClick={() => onSeasonSelected(Number(currentSeason.id))}
                    colorText={
                      currentSeason?.id === currentSeasonNumber
                        ? "#C1101A"
                        : "#FFFFFF"
                    }
                  >
                    {currentSeason.name}{" "}
                    <ArrowDownIcon
                      color={
                        currentSeason?.id === currentSeasonNumber
                          ? "#C1101A"
                          : "#FFFFFF"
                      }
                    />
                  </StyledButton>
                </li>
              );
            })} */}
          </ul>
          <StyledSelectField
            value={currentProductionNumber}
            options={productionOptions}
            onChange={(e) => {
              changeProduction(e.target.value);
            }}
          />
          <div className="flex flex-col gap-y-6">
            {production.chapters
              ? production.chapters.map((chapter: ChapterEntity, i: number) => {
                  return (
                    <ChapterCard
                      onClick={
                        onChapterClick
                          ? () => onChapterClick(chapter.uuid, 0)
                          : () => {}
                      }
                      key={`card${i}`}
                      chapterData={chapter}
                    />
                  );
                })
              : "No chapter available yet"}
            {parseInt(String(production.chapters!.length)) < 1 && (
              <p className="font-primaryBold text-lg lg:text-2xl text-center text-white">
                Esta producción no tiene capítulo.
              </p>
            )}
          </div>
        </>
      )}
    </section>
  );
};

export default LearningPathProduction;
