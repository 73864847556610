import tw from "twin.macro";
import styled from "styled-components";
import { Button, Select } from "../../../../components";
import arrowDownWhite from "../../../../assets/images/arrowDownWhite.svg";

export const StyledButton = styled(Button).attrs({
  className: "StyledButton",
  color: "secondary",
})`
  ${tw`flex items-center leading-6 gap-x-2
 `}
`;

export const StyledSelectField = styled(Select).attrs({
  className: "StyledSelectField",
  selectColor: "secondary",
})`
  ${tw` flex  max-w-[10.5rem]  bg-transparent text-white font-primaryBold rounded-none border-none`}
  border-bottom: 2px solid #e30613;

  & .MuiInputBase-root {
    ${tw`bg-transparent text-white`}
  }
  & .MuiSelect-outlined {
    ${tw`font-primaryBold p-2`}
    vertical-align: middle;
  }

  & svg {
    top: 7px !important;
  }
`;
