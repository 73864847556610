import { FC } from "react";
import {
  StyledButton,
  StyledButtonRent,
  StyledInformationSection,
} from "./InformationSection.styles";
import { FolderIcon, HeartIcon } from "../../../../assets/Icons";
import { Button, RatingModal } from "../../../../components";
import { useNavigation } from "../../../../hooks";
import {
  LearningPathDetailEntity,
  PriceEntity,
  ProductionEntity,
} from "../../../../../domain/entities";
import { FieldValues } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { LearningPathDetailModel } from "../../../../../data/models/learningPathDetail.model";

interface InformationSectionProps {
  token?: string;
  title: string;
  description: string;
  onClick?: (data: any) => void;
  isProductionSaved: boolean;
  isSubscriptionActive: boolean;
  isProductionRented: boolean;
  price?: PriceEntity;
  navigateToRental: () => void;
  userCanComment: boolean;
  showModalRating: boolean;
  onSubmitRating: (data: FieldValues) => void;
  onCancelRating: (data: any) => void;
  onClickRatingButton: (data: any) => void;
  productions?: LearningPathDetailEntity;
  detailLearningPath?: LearningPathDetailModel;
}

enum CurrencySymbol {
  eur = "€",
  usd = "$",
}
export const InformationSection: FC<InformationSectionProps> = ({
  token,
  description,
  title,
  onClick,
  isProductionSaved,
  isProductionRented,
  isSubscriptionActive,
  price,
  navigateToRental,
  userCanComment,
  showModalRating,
  onSubmitRating,
  onCancelRating,
  onClickRatingButton,
  productions,
  detailLearningPath,
}) => {
  console.log(title);
  const { navigateTo } = useNavigation();
  const finalPrice = price
    ? (price?.unitAmount / 100.0).toString().replace(".", ",")
    : "";

  const location = useLocation().pathname;
  return (
    <StyledInformationSection>
      <div className="flex flex-row justify-end items-center py-4 lg:justify-between gap-x-4">
        {title && (
          <h2 className="flex text-left gap-x-4 items-center w-full justify-start text-2xl lg:text-3xl font-primaryBold ">
            {title}
            {/* {isSubscriptionActive && (
              <HeartIcon
                fill={!isProductionSaved ? "none" : "#C1101A"}
                onClick={!isProductionSaved ? onClick : () => {}}
                color="#C1101A"
                className="folderLogo cursor-pointer hidden lg:block"
              />
            )} */}
          </h2>
        )}

        {!userCanComment && (
          <Button
            className="w-full lg:w-32"
            onClick={() => {
              navigateToRental
                ? navigateToRental()
                : navigateTo("/subscriptions");
            }}
          >
            Suscríbete
          </Button>
        )}

        {!token && (
          <div>
            <StyledButton onClick={() => navigateTo("/signup")}>
              ¡Regístrate ahora!
            </StyledButton>
          </div>
        )}
        {isSubscriptionActive && userCanComment && (
          <Button color="secondary" onClick={onClickRatingButton}>
            Calificar este título
          </Button>
        )}
      </div>
      <p className="text-base font-primaryBook ">{description}</p>
      <div>
        {location === "learning-path-detail" && (
          <>
            {detailLearningPath?.productions &&
            detailLearningPath?.productions.length > 0 ? (
              <div className="w-full pb-4 flex flex-col items-end gap-y-6">
                {!isSubscriptionActive && (
                  <div className="w-full flex flex-col gap-8 justify-center lg:justify-end lg:flex-row lg:items-center text-center">
                    <p className="font-primaryBold text-sm lg:text-base">
                      ¿Te gustaría acceder a todos nuestros beneficios?
                    </p>
                    <Button
                      className="w-full lg:w-32"
                      onClick={() => {
                        navigateToRental
                          ? navigateToRental()
                          : navigateTo("/subscriptions");
                      }}
                    >
                      Suscríbete
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <div className="w-full flex flex-col gap-8 items-center justify-center my-4">
                <p className="font-primaryBold text-lg lg:text-2xl">
                  Esta ruta de aprendizaje no tiene producción.
                </p>
              </div>
            )}
          </>
        )}
      </div>
      <RatingModal
        showModal={showModalRating}
        onSubmit={onSubmitRating}
        onCancel={onCancelRating}
      />
    </StyledInformationSection>
  );
};
