import { FC } from "react";
import { createPortal } from "react-dom";
import { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";
import { PillEntity, ProductionEntity } from "../../../../../domain/entities";
import { VideoJS } from "../../../../components";
import {
  StyledVideoPreview,
  StyledVideoContainer,
  StyledVideoInfo,
} from "./VideoPreview.styles";

interface VideoPreviewProps {
  videoOptions: VideoJsPlayerOptions;
  handlePlayerReady?: (player: VideoJsPlayer) => void;
  imgPreview: string;
  format: string;
  terms?: Array<PillEntity>;
  duration: number;
  timeLeft: number;
  chapterName: string;
  currentSeasonNumber: number;
  productionName: string;
  nextChapterName?: string;
  nextChapterImage?: string;
  similarProductions: Array<ProductionEntity>;
  isPodcast?: boolean;
}

export const VideoPreview: FC<VideoPreviewProps> = ({
  videoOptions,
  handlePlayerReady,
  imgPreview,
  format,
  terms,
  duration,
  timeLeft,
  chapterName,
  currentSeasonNumber,
  productionName,
  nextChapterName,
  nextChapterImage,
  similarProductions,
  isPodcast,
}) => {
  return (
    <StyledVideoPreview
      timeLeft={timeLeft}
      chapterName={chapterName}
      productionName={productionName}
      currentSeasonNumber={currentSeasonNumber}
      nextChapterName={nextChapterName}
      nextChapterImage={nextChapterImage}
      similarProductions={similarProductions}
    >
      <StyledVideoContainer>
        <VideoJS
          className="z-0"
          options={videoOptions}
          onReady={handlePlayerReady}
        />
        {isPodcast && (
          <div
            className={`absolute top-0 h-full w-full bg-black bg-center bg-no-repeat bg-contain`}
            style={{
              backgroundImage:
                !!imgPreview && isPodcast ? `url(${imgPreview})` : "none",
            }}
          ></div>
        )}
      </StyledVideoContainer>
      <StyledVideoInfo className="flex flex-wrap lg:flex flex-row justify-evenly">
        <li>
          <p className="capitalize">
            Tema: <span>{!!terms?.length && terms[0].title.toLowerCase()}</span>
            {/* {terms?.map((category: PillEntity, i: number) => (
              <span key={`Term-${i}`} className="font-primaryBook">
                {category.title}
              </span>
            ))} */}
          </p>
        </li>
        <li>
          <p>
            Formato: <span>{format}</span>
          </p>
        </li>
        <li>
          <p>
            Duración: <span>{Math.round(duration / 60)} min.</span>
          </p>
        </li>
      </StyledVideoInfo>
    </StyledVideoPreview>
  );
};
