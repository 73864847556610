import { createSelector } from "./createSelector";
import { LearningPathModel, SessionModel } from "../../models";

export const getSessionToken = createSelector(
  (state: { session: SessionModel | null }) => state.session,
  (session) => session?.token
);

export const getUser = createSelector(
  (state: { session: SessionModel | null }) => state.session,
  (session) => session?.user
);

export const getAvatar = createSelector(
  (state: { session: SessionModel | null }) => state.session,
  (session) => session?.user?.avatar
);

export const getSubscriptionStatus = createSelector(
  (state: { session: SessionModel | null }) => state.session,
  (session) => session?.user?.suscription
);

export const getSavedLearningPath = createSelector(
  (state: { session: LearningPathModel | null }) => state.session,
  (session) => {
    return session?.learnintPathUuid
  }
);
